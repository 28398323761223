
import type { ValidationRule } from 'ant-design-vue/lib/form/Form';
import importComponents from '@/utils/import-components';
import {
  defineComponent, reactive, ref,
} from 'vue';
import RegionWrapper from '@/components/common/RegionWrapper.vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import { useForm } from '@ant-design-vue/use';
import {
  Form, Button,
} from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { updatePassword } from '@/services/user';
import { Alert } from '@/components/Alert/index';
import Input from '@/components/common/Input.vue';
import { useI18n } from 'vue-i18n';

type FormRules = Record<string, ValidationRule | ValidationRule[]>;

type ValidatorRule = {
  field: string;
  fullField: string;
  trigger: string;
  type: string;
}

type ValidatorFunc = (rule: ValidationRule, value: string) => Promise<void>;

export default defineComponent({
  name: 'ChangePassword',
  components: {
    ItemHeader,
    RegionWrapper,
    Input,
    ...importComponents(
      Form,
      Form.Item,
      Button,
    ),
  },

  setup() {
    const { t } = useI18n();
    const changeLoading = ref(false);
    const router = useRouter();
    const onCancel = () => {
      router.push('/user/profile');
    };

    const formField = reactive({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    });

    let form: ReturnType<typeof useForm>;

    // Form表单自定义校验规则
    const rejectFunc = (reason: string) => Promise.reject(new Error(reason));
    const validateLengthFunc = (value: string) => {
      if (value.length < 6) {
        return rejectFunc(t('changePassword.p.p1'));
      }
      if (value.length > 18) {
        return rejectFunc(t('changePassword.p.p2'));
      }
      return Promise.resolve();
    };

    const currentPassword: ValidatorFunc = (rule, value) => {
      if (value === '') {
        return rejectFunc(t('changePassword.p.p3'));
      }
      if (formField.newPassword !== '') {
        form.validate('newPassword');
      }
      return validateLengthFunc(value);
    };
    const newPassword: ValidatorFunc = (rule, value) => {
      if (value === '') {
        return rejectFunc(t('changePassword.p.p4'));
      } if (value === formField.currentPassword) { // 提醒新输入密码跟输入的原密码相等
        return rejectFunc(t('changePassword.p.p5'));
      } if (formField.confirmNewPassword !== '') {
        form.validate('confirmNewPassword');
      }
      return validateLengthFunc(value);
    };
    const confirmNewPassword = (rule: ValidatorRule, value: string) => {
      if (value === '') {
        return rejectFunc(t('changePassword.p.p6'));
      } if (value !== formField.newPassword) { // 提醒两次输入的新密码不相等
        return rejectFunc(t('changePassword.p.p7'));
      }
      return Promise.resolve();
    };
    // 合并校验规则
    const rules = reactive<FormRules>({
      currentPassword: [{ validator: currentPassword, required: true, trigger: 'change' }],
      newPassword: [{ validator: newPassword, required: true, trigger: 'change' }],
      confirmNewPassword: [{ validator: confirmNewPassword, required: true, trigger: 'change' }],
    });

    form = useForm(formField, rules);

    const AlertFunc = (value: string) => {
      const modal = Alert.success(value);
      setTimeout(() => {
        changeLoading.value = false;
        modal.destroy();
        router.push('/user/profile');
      }, 2000);
    };

    const onSave = async () => {
      try {
        const res = await form.validate();
        if (res) {
          const data = {
            inputCurrentPassword: res.currentPassword,
            newPassword: res.confirmNewPassword,
          };
          // 调接口
          try {
            const result = await updatePassword(data);
            changeLoading.value = true;
            if (result.success) {
              AlertFunc(t('changePassword.p.p8'));
            } else {
              changeLoading.value = false;
              const modal = Alert.error(result.message);
              setTimeout(() => {
                modal.destroy();
              }, 2000);
            }
          } catch (e) {
            console.error(e);
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    return {
      formField,
      rules,
      validateInfos: form.validateInfos,
      onSave,
      onCancel,
      changeLoading,
      t,
    };
  },
});
