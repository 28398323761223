<template>
  <region-wrapper>
    <item-header :title="t('changePassword.changePassword')" />
    <div class="change-password-form">
      <a-form
        layout="vertical"
        :model="formField"
        @submit="onSave"
        :rules="rules"
      >
        <a-form-item
          :label="t('changePassword.currentPassword')"
          v-bind="validateInfos.currentPassword"
        >
          <Input :placeholder="t('changePassword.inputCurrentPassword')" v-model:value="formField.currentPassword" :clear="true" type="password"/>
        </a-form-item>
        <a-form-item :label="t('changePassword.newPassword')"  v-bind="validateInfos.newPassword">
          <Input :placeholder="t('changePassword.inputNewPassword')" v-model:value="formField.newPassword" :clear="true" type="password"/>
        </a-form-item>
        <a-form-item
          :label="t('changePassword.confirmNewPassword')"
          v-bind="validateInfos.confirmNewPassword"
        >
          <Input :placeholder="t('changePassword.confirmNewPassword')" v-model:value="formField.confirmNewPassword" :clear="true" type="password"/>
        </a-form-item>
        <a-button type="primary" html-type="submit" :loading="changeLoading">{{t('changePassword.save')}}</a-button>
        <a-button type="primary" class="cancel" @click="onCancel"
          >{{t('changePassword.cancel')}}</a-button
        >
      </a-form>
    </div>
  </region-wrapper>
</template>
<script lang='ts'>
import type { ValidationRule } from 'ant-design-vue/lib/form/Form';
import importComponents from '@/utils/import-components';
import {
  defineComponent, reactive, ref,
} from 'vue';
import RegionWrapper from '@/components/common/RegionWrapper.vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import { useForm } from '@ant-design-vue/use';
import {
  Form, Button,
} from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { updatePassword } from '@/services/user';
import { Alert } from '@/components/Alert/index';
import Input from '@/components/common/Input.vue';
import { useI18n } from 'vue-i18n';

type FormRules = Record<string, ValidationRule | ValidationRule[]>;

type ValidatorRule = {
  field: string;
  fullField: string;
  trigger: string;
  type: string;
}

type ValidatorFunc = (rule: ValidationRule, value: string) => Promise<void>;

export default defineComponent({
  name: 'ChangePassword',
  components: {
    ItemHeader,
    RegionWrapper,
    Input,
    ...importComponents(
      Form,
      Form.Item,
      Button,
    ),
  },

  setup() {
    const { t } = useI18n();
    const changeLoading = ref(false);
    const router = useRouter();
    const onCancel = () => {
      router.push('/user/profile');
    };

    const formField = reactive({
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    });

    let form: ReturnType<typeof useForm>;

    // Form表单自定义校验规则
    const rejectFunc = (reason: string) => Promise.reject(new Error(reason));
    const validateLengthFunc = (value: string) => {
      if (value.length < 6) {
        return rejectFunc(t('changePassword.p.p1'));
      }
      if (value.length > 18) {
        return rejectFunc(t('changePassword.p.p2'));
      }
      return Promise.resolve();
    };

    const currentPassword: ValidatorFunc = (rule, value) => {
      if (value === '') {
        return rejectFunc(t('changePassword.p.p3'));
      }
      if (formField.newPassword !== '') {
        form.validate('newPassword');
      }
      return validateLengthFunc(value);
    };
    const newPassword: ValidatorFunc = (rule, value) => {
      if (value === '') {
        return rejectFunc(t('changePassword.p.p4'));
      } if (value === formField.currentPassword) { // 提醒新输入密码跟输入的原密码相等
        return rejectFunc(t('changePassword.p.p5'));
      } if (formField.confirmNewPassword !== '') {
        form.validate('confirmNewPassword');
      }
      return validateLengthFunc(value);
    };
    const confirmNewPassword = (rule: ValidatorRule, value: string) => {
      if (value === '') {
        return rejectFunc(t('changePassword.p.p6'));
      } if (value !== formField.newPassword) { // 提醒两次输入的新密码不相等
        return rejectFunc(t('changePassword.p.p7'));
      }
      return Promise.resolve();
    };
    // 合并校验规则
    const rules = reactive<FormRules>({
      currentPassword: [{ validator: currentPassword, required: true, trigger: 'change' }],
      newPassword: [{ validator: newPassword, required: true, trigger: 'change' }],
      confirmNewPassword: [{ validator: confirmNewPassword, required: true, trigger: 'change' }],
    });

    form = useForm(formField, rules);

    const AlertFunc = (value: string) => {
      const modal = Alert.success(value);
      setTimeout(() => {
        changeLoading.value = false;
        modal.destroy();
        router.push('/user/profile');
      }, 2000);
    };

    const onSave = async () => {
      try {
        const res = await form.validate();
        if (res) {
          const data = {
            inputCurrentPassword: res.currentPassword,
            newPassword: res.confirmNewPassword,
          };
          // 调接口
          try {
            const result = await updatePassword(data);
            changeLoading.value = true;
            if (result.success) {
              AlertFunc(t('changePassword.p.p8'));
            } else {
              changeLoading.value = false;
              const modal = Alert.error(result.message);
              setTimeout(() => {
                modal.destroy();
              }, 2000);
            }
          } catch (e) {
            console.error(e);
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    return {
      formField,
      rules,
      validateInfos: form.validateInfos,
      onSave,
      onCancel,
      changeLoading,
      t,
    };
  },
});
</script>
<style lang='scss' scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";
:deep(header) {
  h2 {
    border-bottom: 1px solid#EDEDED;
    padding-bottom: 21px;
    margin-top: 20px;
    margin-bottom: 34px;
  }
}
.change-password-form {
  margin-bottom: 71px;
  :deep(.ant-form) {
    .ant-row.ant-form-item {
      padding: 0;
      margin-bottom: 35px;
      .ant-col {
        padding: 0px;
        label {
          font-size: 14px;
          font-weight: 400;
          color: $font-color;
          line-height: 11px;
        }
      }
      .ant-form-item-control-wrapper {
        position: relative;
        .ant-form-item-control .ant-form-item-children {
          .ant-input-affix-wrapper {
            &:hover {
              border-color: $theme-color;
            }
          }
          .ant-input-affix-wrapper-focused {
            border: 1px solid $theme-color;
            box-shadow: $box-shadow;
          }
          .ant-input-password {
            width: 350px;
            height: 40px;
            background: #ffffff;
            border-radius: 4px;
          }
        }
        .ant-form-explain {
          position: absolute;
          top: 100%;
          left: 0;
          width: 350px;
          font-size: 12px;
          font-weight: 400;
          color: $theme-color;
          line-height: 15px;
        }
      }
    }
  }
  .ant-btn-primary {
    width: 150px;
    height: 44px;
    background: $theme-color;
    border-radius: 4px;
    border-color: $theme-color;
    margin-right: 25px;
    margin-top: 11px;
    font-weight: bold;
    &.cancel {
      background: #969696;
      border-color: #969696;
      margin-left: 25px;
      margin-right: 0;
    }
    span {
      width: 46px;
      height: 20px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      line-height: 20px;
    }
  }
}
</style>
